import { I18N } from "aurelia-i18n";
import { IWarehouse } from "domain/Client/IWarehouse";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { LogManager, autoinject, bindable } from "aurelia-framework";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";
import { ICartProduct } from "domain/Cart/ICartProduct";

export const log = LogManager.getLogger("app.cart.index");

@autoinject
export class TransportListCustomElement {
  @bindable allowedTransportOptions: Map<string, string>;
  @bindable warehouses: IWarehouse[];
  userSpecifiedAddresses: string[];
  combinedWarehouses = [1, 3];

  @bindable products: ICartProduct[];
  error: string;

  // map contents: (warehouseId; transportId)
  selectedTransportsForWarehouses = new Map<number, string>();

  @bindable comment = "";
  selectedTransportAddress = "";

  totalWithoutVat = 0;
  totalVat = 0;
  totalWithVat = 0;

  options = {
    showImages: true,
    showEan: true,
    showStock: true,
    showWarehouse: true,
    showReason: false,
    showDisplayPrice: true,
  };

  constructor(
    private cartProductService: CartProductService,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N,
  ) {
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateSelectedTransports();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsWarehouseChanged, (product: IProduct) => {
      this.updateCartProductWarehouseId(product);
      this.updateSelectedTransports();
    });

    this.userSpecifiedAddresses = this.appConfig.contractSettings.otherTransportAddresses;
  }

  attached() {
    this.updateSelectedTransports();
  }

  // #region OBSERVABLES, LISTENERS

  changeTransportForWarehouse(warehouseId: number, newTransportId: string) {
    this.selectedTransportsForWarehouses.set(warehouseId, newTransportId);

    if (this.isWarehouseCombinationPresent() && this.isCombinedWarehouse(warehouseId)) {
      this.selectedTransportsForWarehouses.set(
        this.getOtherCombinedWarehouse(warehouseId),
        newTransportId,
      );
    }

    const productsToUpdate = this.products.filter(
      product =>
        (this.isExternalWarehouse(warehouseId) && product.externalWarehouseId) ||
        product.warehouseId == warehouseId ||
        product.warehouseId == this.getOtherCombinedWarehouse(warehouseId),
    );
    productsToUpdate.forEach(product => {
      if (product.transportId == newTransportId) return;

      product.transportId = newTransportId;
      this.updateSelectedTransports();

      // Send changed product to BE
      const cartProduct = {
        cartProductId: product.cartProductId ?? 0,
        foundBy: product.foundBy,
        code: product.code,
        quantity: parseInt(product.quantity.toString()),
        comment: product.comment,
        warehouseId: product.selectedStock?.warehouse?.id ?? product.warehouseId ?? 0,
        transportId: parseInt(product.transportId ?? "0"),
      } as any;

      if (product.selectedStock?.warehouse?.isExternal) {
        cartProduct.warehouseId = 0;
        cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
      }

      this.cartProductService
        .putExistingProduct(cartProduct)
        .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.update")));
    });
  }

  updateSelectedTransports() {
    if (!this.products) return;

    this.selectedTransportsForWarehouses = new Map<number, string>();

    for (const p of this.products) {
      if (p.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(-1, p.transportId ?? "0");
        continue;
      }

      if (
        p.warehouseId != this.appConfig.userSettings.warehouseId &&
        !this.combinedWarehouses.includes(p.warehouseId ?? 0)
      ) {
        this.selectedTransportsForWarehouses.set(p.warehouseId ?? 0, "802");
        continue;
      }

      this.selectedTransportsForWarehouses.set(p.warehouseId ?? 0, p.transportId ?? "0");
    }
  }

  updateCartProductWarehouseId(product: IProduct) {
    const cartProduct = this.products.find(p => p.id == product.id);
    if (!cartProduct) return;

    if (!product.selectedStock?.warehouse?.isExternal) {
      cartProduct.externalWarehouseId = undefined;
      cartProduct.warehouseId = product.selectedStock?.warehouse?.id;
    } else {
      cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
      cartProduct.warehouseId = 0;
    }

    this.preselectOnlyTransportOption();
  }

  // #endregion

  // #region HELPERS

  getWarehouseName(warehouseId: number): string {
    if (warehouseId == -1) return this.i18n.tr("components.products.headers.partner-warehouse");
    if (this.isWarehouseCombinationPresent() && this.isCombinedWarehouse(warehouseId))
      return "Keskladu/Mustamäe";

    if (this.warehouses != undefined)
      for (const warehouse of this.warehouses)
        if (warehouse.id == warehouseId) return warehouse.name;

    return `ID: ${warehouseId}`;
  }

  getTransportName(transportId: string): string {
    return this.allowedTransportOptions.get(transportId) ?? "?";
  }

  anyExternalWarehouseProducts() {
    return this.products.some(x => !!x.externalWarehouseId);
  }

  preselectOnlyTransportOption() {
    if (this.allowedTransportOptions.size != 1) return;

    const transportId = this.allowedTransportOptions.keys().next().value;

    this.products.forEach(product => {
      if (product.transportId == transportId) return;

      product.transportId = transportId;

      if (!product.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(
          product.warehouseId ?? 0,
          product.transportId ?? "0",
        );
      }
    });
  }

  canChangeTransport(warehouseId: number): boolean {
    return true; // TEMP: always allow until better solution is found
    /* return (
      this.isExternalWarehouse(warehouseId) ||
      warehouseId == this.appConfig.userSettings.warehouseId ||
      this.isCombinedWarehouse(warehouseId)
    ); */
  }

  isWarehouseCombinationPresent() {
    const hasFirstWarehouse = this.products.some(p => p.warehouseId === this.combinedWarehouses[0]);
    const hasSecondWarehouse = this.products.some(
      p => p.warehouseId === this.combinedWarehouses[1],
    );

    return hasFirstWarehouse && hasSecondWarehouse;
  }

  isCombinedWarehouse(warehouseId: number): boolean {
    return this.combinedWarehouses.includes(warehouseId);
  }

  getMainCombinedWarehouse(): number {
    return this.combinedWarehouses[0];
  }

  getOtherCombinedWarehouse(warehouseId: number): number {
    return this.combinedWarehouses.find(id => id !== warehouseId) ?? 0;
  }

  shouldDisplayInList(warehouseId: number): boolean {
    return !(this.isWarehouseCombinationPresent() && warehouseId == this.combinedWarehouses[1]);
  }

  isExternalWarehouse(warehouseId: number): boolean {
    return warehouseId === -1;
  }

  //#endregion
}
